export const styles = {
  alpha: {
    slots: {
      signInWrapper:
        'flex flex-col bg-brand-secondary-800 px-sm py-md rounded-t-lg gap-xs border-brand-gray-300 border border-b-0',
      heading:
        'mx-auto text-[18px] font-semibold leading-6 font-heading  text-brand-white mb-sm',
    },
  },
  bravo: {
    slots: {
      signInWrapper:
        'flex flex-col bg-brand-secondary-800 px-sm py-md rounded-t-lg gap-xs border-brand-gray-300 border border-b-0',
      heading:
        'mx-auto text-[18px] font-semibold leading-6 font-heading  text-brand-white mb-sm',
    },
  },
  charlie: {
    slots: {
      signInWrapper:
        'flex flex-col bg-brand-blue-900 px-sm py-md rounded-t-lg gap-xs border-brand-gray-300 border border-b-0',
      heading: 'mx-auto font-h4 text-brand-white mb-sm',
    },
  },
}
