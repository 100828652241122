<script setup lang="ts">
import { tv } from 'tailwind-variants'
import { password } from './passwordStyles'
import { LoginView } from '~/enums'

const theme = useRuntimeConfig().public.theme as keyof typeof password
const passwordStyling = password[theme]
const loginPage = useState('loginPage')
const { $auth } = useNuxtApp()

const styling = tv({
  extend: passwordStyling,
})

const passwordInput = useState<string>('passwordInput')
const emailInput = useState<string>('emailInput')

const { wrapper, heading, link, divider, text } = styling()

const signIn = () => {
  try {
    $auth.signInWithEmailPassoword({
      email: emailInput.value,
      password: passwordInput.value,
    })
  } catch {}
}
defineEmits(['validate'])
</script>

<template>
  <div>
    <form :class="wrapper()" onsubmit="return false">
      <CPAuthBackButton :page="LoginView.signIn" />
      <span :class="heading()">{{ $t('cp.login.password.heading') }}</span>
      <i18n-t :class="text()" tag="div" :keypath="`cp.login.password.text`">
        <template #email>
          <div class="font-semibold">{{ emailInput }}</div>
        </template>
      </i18n-t>
      <div class="flex flex-col gap-2 mt-8">
        <CPFormPasswordInput />
        <CPButton
          :loading="$auth.loading.value"
          color="primary"
          size="sm"
          @click="$emit('validate', signIn)"
          >{{ $t('cp.login.password.button') }}</CPButton
        >
        <div :class="divider()"></div>
        <CPLink :class="link()" @click="loginPage = LoginView.forgotPassword">
          {{ $t('cp.login.password.link') }}
        </CPLink>
      </div>
    </form>
    <CPAuthBookingCredentialsSection />
  </div>
</template>
