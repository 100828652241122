<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, sameAs } from '@vuelidate/validators'

const { t } = useI18n()

const passwordInput = useState<string>('passwordInput')
const confirmPasswordInput = useState<string>('confirmPasswordInput')
const passwordVisibility = ref(false)

const rules = {
  confirmPasswordInput: {
    required: helpers.withMessage(t('validations.required'), required),
    sameAs: helpers.withMessage(
      t('validations.same-as', { variable: t('login.password') }),
      sameAs(passwordInput)
    ),
  },
}

const v$ = useVuelidate(rules, { confirmPasswordInput })
</script>

<template>
  <CPFormInputField
    v-model="confirmPasswordInput"
    class="w-full"
    :type="passwordVisibility ? 'text' : 'password'"
    name="confirm password"
    :placeholder="$t('cp.login.placeholder.confirm-password')"
    :label="$t('cp.login.label.repeat-password')"
    :error-text="(v$.confirmPasswordInput.$errors[0]?.$message as string | undefined)"
    autocomplete="new-password"
    ><template #trailing>
      <Icon
        size="21"
        class="cursor-pointer"
        :name="
          passwordVisibility
            ? 'material-symbols:visibility'
            : 'material-symbols:visibility-off'
        "
        @click="passwordVisibility = !passwordVisibility"
      /> </template
  ></CPFormInputField>
</template>
