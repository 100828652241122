<script setup lang="ts">
import { tv } from 'tailwind-variants'
import { signIn } from './attemptsExceededStyles'
import { LoginView } from '~/enums'

const theme = useRuntimeConfig().public.theme as keyof typeof signIn
const sigInStyling = signIn[theme]
const loginPage = useState('loginPage')
const loginTransition = useState('loginTransition')

const styling = tv({
  extend: sigInStyling,
})

const { wrapper, heading, text } = styling()
</script>

<template>
  <div>
    <div :class="wrapper()">
      <div class="text-center mb-md">
        <div :class="heading()">
          {{ $t('cp.login.too-many-login-attempts.heading') }}
        </div>
        <Icon
          size="60"
          class="text-brand-danger-600"
          name="material-symbols-light:cancel-outline"
        />
        <div :class="text()">
          {{ $t('cp.login.too-many-login-attempts.text1') }}
        </div>
        <div :class="text()">
          {{ $t('cp.login.too-many-login-attempts.text2') }}
        </div>
      </div>
      <CPButton
        color="alternative"
        size="xs"
        @click="
          () => {
            loginTransition = 'right'
            loginPage = LoginView.signIn
          }
        "
      >
        <Icon size="24" name="material-symbols-light:chevron-left-rounded" />
        {{ $t('cp.login.too-many-login-attempts.button') }}</CPButton
      >
    </div>
    <CPAuthBookingCredentialsSection />
  </div>
</template>
