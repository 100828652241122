<script setup lang="ts">
import { email, required, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

const { t } = useI18n()

const emailInput = useState<string>('emailInput')

const rules = {
  emailInput: {
    required: helpers.withMessage(t('validations.required'), required),
    email: helpers.withMessage(t('validations.email'), email),
  },
}

const v$ = useVuelidate(rules, { emailInput })
</script>

<template>
  <CPFormInputField
    v-model="emailInput"
    class="w-full"
    type="email"
    name="email"
    :placeholder="$t('cp.login.placeholder.email')"
    :label="$t('cp.login.label.email')"
    :error-text="(v$.emailInput.$errors[0]?.$message as string | undefined)"
  />
</template>
