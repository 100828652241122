<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { LoginView } from '~/enums'
definePageMeta({
  analyticsType: 'customer_portal',
  cpAnalyticsType: 'login',
  layout: false,
})

const { t } = useI18n()
useSeoMeta({
  title: t('cp.page-title.login'),
})

const route = useRoute()
const { $auth } = useNuxtApp()
const AuthSignIn = resolveComponent('CPAuthSignIn')
const AuthPassword = resolveComponent('CPAuthPassword')
const AuthBookingSignIn = resolveComponent('CPAuthBookingSignIn')
const AuthCheckInbox = resolveComponent('CPAuthCheckInbox')
const AuthCreatePassword = resolveComponent('CPAuthCreatePassword')
const AuthForgotPassword = resolveComponent('CPAuthForgotPassword')
const AuthResetPassword = resolveComponent('CPAuthResetPassword')
const CPAuthForgotBookingRef = resolveComponent('CPAuthForgotBookingRef')
const CPAuthAttemptsExceeded = resolveComponent('CPAuthAttemptsExceeded')

const pageView = useState<LoginView>('loginPage', () => {
  const queryValue = Object.values(LoginView).find(
    (f) => f === route.query.view
  )
  if (
    queryValue &&
    queryValue !== LoginView.createPassword &&
    queryValue !== LoginView.password
  ) {
    return queryValue
  }
  return LoginView.signIn
})

const currentComponenet = computed(() => {
  switch (pageView.value) {
    case LoginView.signIn:
      return AuthSignIn
    case LoginView.password:
      return AuthPassword
    case LoginView.createPassword:
      return AuthCreatePassword
    case LoginView.forgotPassword:
      return AuthForgotPassword
    case LoginView.checkInbox:
      return AuthCheckInbox
    case LoginView.signInAttemptsExceeded:
      return CPAuthAttemptsExceeded
    case LoginView.bookingSignIn:
      return AuthBookingSignIn
    case LoginView.forgotBookingReference:
      return CPAuthForgotBookingRef
    case LoginView.resetPassword:
      return AuthResetPassword
    default:
      return AuthSignIn
  }
})

const transition = useState('loginTransition', () => 'left')

const v$ = useVuelidate()

const validate = (callback: () => void) => {
  v$.value.$validate()
  if (!v$.value.$invalid) {
    if (!$auth.loading.value) callback()
  }
}
</script>

<template>
  <NuxtLayout name="cp-default">
    <div class="max-w-[358px] mx-auto py-lg min-h-[50vh]">
      <Transition
        :name="transition"
        mode="out-in"
        @after-leave="transition = 'left'"
      >
        <component
          :is="currentComponenet"
          :token="route.query.token"
          @validate="validate"
        />
      </Transition>
    </div>
  </NuxtLayout>
</template>

<style>
.right-enter-active,
.right-leave-active {
  transition: all 0.2s;
}
.right-enter-from {
  opacity: 0;
  transform: translateX(-200px);
}
.right-leave-to {
  opacity: 0;
  transform: translateX(200px);
}

.left-enter-active,
.left-leave-active {
  transition: all 0.2s;
}
.left-enter-from {
  opacity: 0;
  transform: translateX(200px);
}
.left-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}
</style>
