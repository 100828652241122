<script setup lang="ts">
import { tv } from 'tailwind-variants'
import { createPassword } from './resetPasswordStyles'

const theme = useRuntimeConfig().public.theme as keyof typeof createPassword
const passwordStyling = createPassword[theme]
const { $auth } = useNuxtApp()

interface Props {
  token?: string
}
const props = withDefaults(defineProps<Props>(), {
  token: '',
})

const styling = tv({
  extend: passwordStyling,
})

const passwordInput = useState('passwordInput')
const confirmPasswordInput = useState('confirmPasswordInput')

const submitForm = async () => {
  $auth.resetPassword(
    props.token as string,
    passwordInput.value,
    confirmPasswordInput.value
  )
}

defineEmits(['validate'])

const { wrapper, heading, text } = styling()
</script>

<template>
  <form :class="wrapper()" onsubmit="return false">
    <span :class="heading()">{{ $t('cp.login.reset-password.heading') }}</span>
    <span :class="text()">{{ $t('cp.login.reset-password.text') }}</span>
    <div class="flex flex-col gap-2 mt-9">
      <div class="flex flex-col gap-md mb-xs">
        <CPFormPasswordInput />
        <CPFormConfirmPasswordInput />
      </div>
      <CPButton
        :loading="$auth.loading.value"
        color="primary"
        size="sm"
        type="submit"
        @click="$emit('validate', submitForm)"
        >{{ $t('cp.login.reset-password.button') }}</CPButton
      >
    </div>
  </form>
</template>
