<script setup lang="ts">
import { tv } from 'tailwind-variants'
import { createPassword } from './createPasswordStyles'
import { LoginView } from '~/enums'

const theme = useRuntimeConfig().public.theme as keyof typeof createPassword
const passwordStyling = createPassword[theme]
const { $auth } = useNuxtApp()

const styling = tv({
  extend: passwordStyling,
})

const passwordInput = useState<string>('passwordInput')
const emailInput = useState<string>('emailInput')

const submitForm = async () => {
  $auth.signUp({
    email: emailInput.value,
    password: passwordInput.value,
  })
}

defineEmits(['validate'])

const { wrapper, heading, text } = styling()
</script>

<template>
  <div>
    <form :class="wrapper()" onsubmit="return false">
      <CPAuthBackButton :page="LoginView.signIn" />
      <span :class="heading()">{{
        $t('cp.login.create-password.heading')
      }}</span>
      <span :class="text()">{{ $t('cp.login.create-password.text') }}</span>
      <div class="flex flex-col gap-2 mt-9">
        <div class="flex flex-col gap-md mb-xs">
          <CPFormPasswordInput />
          <CPFormConfirmPasswordInput />
        </div>
        <CPButton
          :loading="$auth.loading.value"
          color="primary"
          size="sm"
          type="submit"
          @click="$emit('validate', submitForm)"
          >{{ $t('cp.login.create-password.button') }}</CPButton
        >
      </div>
    </form>
    <CPAuthBookingCredentialsSection />
  </div>
</template>
