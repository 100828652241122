export const button = {
  alpha: {
    base: '-translate-y-2',
  },
  bravo: {
    base: '-translate-y-2',
  },
  charlie: {
    base: '-translate-y-2',
  },
}
