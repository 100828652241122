export const password = {
  alpha: {
    slots: {
      wrapper:
        'flex flex-col bg-brand-secondary-800 px-sm py-md rounded-lg  border-brand-gray-300 border border-b-0',
      heading:
        'mx-auto text-[18px] font-semibold leading-6 font-heading  text-brand-white',
      text: 'text-brand-white text-xs mx-lg text-center',
      divider: 'border-b border-brand-gray-300 my-xs',
      link: 'mx-auto',
      backButton:
        'text-brand-secondary-400 text-md cursor-pointer  flex self-start -translate-y-2',
    },
  },
  bravo: {
    slots: {
      wrapper:
        'flex flex-col bg-brand-secondary-800 px-sm py-md rounded-lg  border-brand-gray-300 border border-b-0',
      heading:
        'mx-auto text-[18px] font-semibold leading-6 font-heading  text-brand-white',
      text: 'text-brand-white text-xs mx-lg text-center',
      divider: 'border-b border-brand-gray-300 my-xs',
      link: 'mx-auto',
      backButton:
        'text-brand-secondary-400 text-md cursor-pointer  flex self-start -translate-y-2',
    },
  },
  charlie: {
    slots: {
      wrapper:
        'flex flex-col bg-brand-blue-900 px-sm py-md rounded-t-lg  border-brand-gray-300 border border-b-0',
      heading:
        'mx-auto text-[18px] font-semibold leading-6 font-heading  text-brand-white',
      text: 'text-brand-white text-xs mx-lg text-center',
      divider: 'border-b border-brand-gray-300 my-xs',
      link: 'mx-auto',
      backButton:
        'text-brand-secondary-400 text-md cursor-pointer  flex self-start -translate-y-2',
    },
  },
}
