<script setup lang="ts">
import { tv } from 'tailwind-variants'
import { styles } from './signInStyles'

const theme = useRuntimeConfig().public.theme as keyof typeof styles
const sigInStyling = styles[theme]
const { $auth } = useNuxtApp()

const styling = tv({
  extend: sigInStyling,
})

const { signInWrapper, heading } = styling()

const emailInput = useState<string>('emailInput')

const validateLogin = async () => {
  try {
    $auth.getUserStatus(emailInput.value)
  } catch {}
}

defineEmits(['validate'])
</script>

<template>
  <div>
    <form :class="signInWrapper()" onsubmit="return false">
      <span :class="heading()">{{ $t('cp.login.sign-in.heading') }}</span>
      <CPFormEmailInput />
      <CPButton
        :loading="$auth.loading.value"
        color="primary"
        size="sm"
        @click="$emit('validate', validateLogin)"
        >{{ $t('cp.login.sign-in.button') }}</CPButton
      >
    </form>
    <CPAuthBookingCredentialsSection />
  </div>
</template>
