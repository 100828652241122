<script setup lang="ts">
import { tv } from 'tailwind-variants'
import { forgotBookingReference } from './forgotBookingRefStyles'
import { LoginView } from '~/enums'

const theme = useRuntimeConfig().public
  .theme as keyof typeof forgotBookingReference
const sigInStyling = forgotBookingReference[theme]
const { $auth } = useNuxtApp()

const styling = tv({
  extend: sigInStyling,
})

const emailInput = useState('emailInput')

const sendEmail = async () => {
  try {
    $auth.forgotBookingReference(emailInput.value)
  } catch (error) {}
}

defineEmits(['validate'])

const { signInWrapper, heading, text1, text2 } = styling()
</script>

<template>
  <form :class="signInWrapper()" onsubmit="return false">
    <CPAuthBackButton :page="LoginView.bookingSignIn" />
    <div class="text-center flex flex-col gap-xxs">
      <span :class="heading()">{{
        $t('cp.login.forgot-booking-reference.heading')
      }}</span>
      <span :class="text1()">{{
        $t('cp.login.forgot-booking-reference.text1')
      }}</span>
      <span :class="text2()">{{
        $t('cp.login.forgot-booking-reference.text2')
      }}</span>
    </div>
    <div class="flex flex-col gap-xs">
      <CPFormEmailInput />
      <CPButton
        :loading="$auth.loading.value"
        color="primary"
        size="sm"
        type="submit"
        @click="$emit('validate', sendEmail)"
        >{{ $t('cp.login.forgot-booking-reference.button') }}</CPButton
      >
    </div>
  </form>
</template>
