export const signIn = {
  alpha: {
    slots: {
      wrapper:
        'flex flex-col bg-brand-secondary-800 px-sm py-md rounded-lg border-brand-gray-300 border border-b-0',
      heading:
        'mx-auto text-[18px] font-semibold leading-6 font-heading  text-brand-white text-center mt-sm',
      text: 'text-brand-white text-xs text-center',
    },
  },
  bravo: {
    slots: {
      wrapper:
        'flex flex-col bg-brand-secondary-800 px-sm py-md rounded-lg border-brand-gray-300 border border-b-0',
      heading:
        'mx-auto text-[18px] font-semibold leading-6 font-heading  text-brand-white text-center mt-sm',
      text: 'text-brand-white text-xs text-center',
    },
  },
  charlie: {
    slots: {
      wrapper:
        'flex flex-col bg-brand-blue-900 px-sm py-md rounded-lg border-brand-gray-300 border border-b-0',
      heading: 'mx-auto font-h4 text-brand-white text-center mb-xs',
      text: 'text-brand-white font-p12 text-center',
    },
  },
}
