<script lang="ts" setup>
import { tv } from 'tailwind-variants'
import { button } from './backButtonStyles'

interface Props {
  page: string
}
defineProps<Props>()

const theme = useRuntimeConfig().public.theme as keyof typeof button
const defaultButton = button[theme]

const loginPage = useState('loginPage')
const loginTransition = useState('loginTransition')

const ui = computed(() =>
  tv({
    extend: defaultButton,
  })
)
</script>

<template>
  <CPLink
    :class="ui()"
    @click="
      () => {
        loginTransition = 'right'
        loginPage = page
      }
    "
  >
    <Icon size="24" name="material-symbols-light:chevron-left-rounded" />
    {{ $t('cp.login.back-button') }}
  </CPLink>
</template>
