export const forgotBookingReference = {
  alpha: {
    slots: {
      signInWrapper:
        'flex flex-col bg-brand-secondary-800 px-sm py-md rounded-lg gap-md border-brand-gray-300 border border-b-0',
      heading:
        'mx-auto text-[18px] font-semibold leading-6 font-heading  text-brand-white',
      text1: 'text-brand-white mb-md mx-md',
      text2: 'text-brand-white text-xs mx-lg',
      divider: 'border-b border-brand-gray-300',
      bookingCredentialsLink:
        'text-brand-secondary-400 text-md leading-7 flex justify-center items-center cursor-pointer',
    },
  },
  bravo: {
    slots: {
      signInWrapper:
        'flex flex-col bg-brand-secondary-800 px-sm py-md rounded-lg gap-md border-brand-gray-300 border border-b-0',
      heading:
        'mx-auto text-[18px] font-semibold leading-6 font-heading  text-brand-white',
      text1: 'text-brand-white mb-md mx-md',
      text2: 'text-brand-white text-xs mx-lg',
      divider: 'border-b border-brand-gray-300',
      bookingCredentialsLink:
        'text-brand-secondary-400 text-md leading-7 flex justify-center items-center cursor-pointer',
    },
  },
  charlie: {
    slots: {
      signInWrapper:
        'flex flex-col bg-brand-blue-900 px-sm py-md rounded-lg gap-md border-brand-gray-300 border border-b-0',
      heading: 'mx-auto font-h4 text-brand-white',
      text1: 'text-brand-white font-p12 mb-md mx-md',
      text2: 'text-brand-white font-p12 mx-[2rem]',
      divider: 'border-b border-brand-gray-300',
      bookingCredentialsLink:
        'text-brand-secondary-400 text-md leading-7 flex justify-center items-center cursor-pointer',
    },
  },
}
