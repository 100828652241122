<script setup lang="ts">
import { required, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

const { t } = useI18n()

const bookingRefInput = useState<string>('bookingRefInput')

const rules = {
  bookingRefInput: {
    required: helpers.withMessage(t('validations.required'), required),
  },
}

const v$ = useVuelidate(rules, { bookingRefInput })
</script>

<template>
  <CPFormInputField
    v-model="bookingRefInput"
    class="w-full"
    type="text"
    name="bookingRef"
    :placeholder="$t('cp.login.placeholder.booking-reference')"
    :error-text="(v$.bookingRefInput.$errors[0]?.$message as string | undefined)"
    :label="$t('cp.login.label.booking-reference')"
  />
</template>
