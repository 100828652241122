<script setup lang="ts">
import { tv } from 'tailwind-variants'
import { styles } from './checkInboxStyles'
import { LoginView } from '~/enums'

const theme = useRuntimeConfig().public.theme as keyof typeof styles
const sigInStyling = styles[theme]
const loginPage = useState('loginPage')
const loginTransition = useState('loginTransition')

const styling = tv({
  extend: sigInStyling,
})

const { wrapper, heading, text, checkmark } = styling()
</script>

<template>
  <div>
    <div :class="wrapper()">
      <Icon
        size="48"
        :class="checkmark()"
        name="material-symbols:task-alt-rounded"
      />
      <div>
        <div :class="heading()">
          {{ $t('cp.login.check-inbox.heading') }}
        </div>
        <div :class="text()">
          {{
            $t('cp.login.check-inbox.text', {
              email: useState('emailInput').value,
            })
          }}
        </div>
      </div>
      <CPButton
        color="alternative"
        size="xs"
        @click="
          () => {
            loginTransition = 'right'
            loginPage = LoginView.signIn
          }
        "
        ><Icon size="24" name="material-symbols-light:chevron-left-rounded" />
        {{ $t('cp.login.check-inbox.button') }}</CPButton
      >
    </div>
    <CPAuthBookingCredentialsSection />
  </div>
</template>
