<script setup lang="ts">
import { tv } from 'tailwind-variants'
import { password } from './forgotPasswordStyles'
import { LoginView } from '~/enums'

const theme = useRuntimeConfig().public.theme as keyof typeof password
const passwordStyling = password[theme]
const loginPage = useState('loginPage')
const { $auth } = useNuxtApp()

const styling = tv({
  extend: passwordStyling,
})

const emailInput = useState<string>('emailInput')

const sendPassword = async () => {
  try {
    const response = await $auth.getResetPasswordLink(emailInput.value)

    if (response) loginPage.value = LoginView.checkInbox
  } catch (error) {}
}
defineEmits(['validate'])

const { wrapper, heading, text } = styling()
</script>

<template>
  <div>
    <form :class="wrapper()" onsubmit="return false">
      <CPAuthBackButton :page="LoginView.signIn" />
      <span :class="heading()">{{
        $t('cp.login.forgot-password.heading')
      }}</span>
      <span :class="text()">{{ $t('cp.login.forgot-password.text') }}</span>
      <div class="flex flex-col gap-2 mt-8">
        <CPFormEmailInput />
        <CPButton
          :loading="$auth.loading.value"
          color="primary"
          size="sm"
          type="submit"
          @click="$emit('validate', sendPassword)"
          >{{ $t('cp.login.forgot-password.button') }}</CPButton
        >
      </div>
    </form>
    <CPAuthBookingCredentialsSection />
  </div>
</template>
