<script setup lang="ts">
import { tv } from 'tailwind-variants'
import { bookingSignIn } from './bookingSignInstyles'
import { LoginView } from '~/enums'

const theme = useRuntimeConfig().public.theme as keyof typeof bookingSignIn
const sigInStyling = bookingSignIn[theme]
const loginPage = useState('loginPage')
const { $auth } = useNuxtApp()

const styling = tv({
  extend: sigInStyling,
})

const emailInput = useState<string>('emailInput')
const bookingRefInput = useState<string>('bookingRefInput')

const signIn = () => {
  try {
    $auth.signInWithBookingRefEmail(emailInput.value, bookingRefInput.value)
  } catch {}
}

defineEmits(['validate'])

const { wrapper, heading, text, divider, link } = styling()
</script>

<template>
  <form :class="wrapper()" onsubmit="return false">
    <CPAuthBackButton :page="LoginView.signIn" />
    <div class="text-center flex flex-col gap-xxs">
      <span :class="heading()">{{
        $t('cp.login.booking-sign-in.heading')
      }}</span>
      <span :class="text()">{{ $t('cp.login.booking-sign-in.text') }}</span>
    </div>
    <CPFormBookingRefInput />
    <CPFormEmailInput />
    <CPButton
      :loading="$auth.loading.value"
      color="primary"
      size="sm"
      type="submit"
      @click="$emit('validate', signIn)"
      >{{ $t('cp.login.booking-sign-in.sign-in-button') }}</CPButton
    >
    <div :class="divider()"></div>
    <CPLink
      :class="link()"
      @click="loginPage = LoginView.forgotBookingReference"
    >
      {{ $t('cp.login.booking-sign-in.link') }}
    </CPLink>
  </form>
</template>
