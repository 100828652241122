<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, minLength } from '@vuelidate/validators'

const { t } = useI18n()

const passwordInput = useState<string>('passwordInput')
const passwordVisibility = ref(false)

const containsCapitalLetter = (value: string) => /[A-Z]/.test(value)
const containsLowecaseLetter = (value: string) => /[a-z]/.test(value)

const containsNumber = (value: string) => /[0-9]/.test(value)

const rules = {
  passwordInput: {
    required: helpers.withMessage(t('validations.required'), required),
    containsCapitalLetter: helpers.withMessage(
      t('validations.capital-letter'),
      containsCapitalLetter
    ),
    containsLowecaseLetter: helpers.withMessage(
      t('validations.lowercase-letter'),
      containsLowecaseLetter
    ),
    containsNumber: helpers.withMessage(
      t('validations.contain-number'),
      containsNumber
    ),
    minLength: helpers.withMessage(
      t('validations.length-minimum', { number: 8 }),
      minLength(8)
    ),
  },
}

const v$ = useVuelidate(rules, { passwordInput })
</script>

<template>
  <CPFormInputField
    v-model="passwordInput"
    class="w-full"
    :type="passwordVisibility ? 'text' : 'password'"
    name="password"
    :placeholder="$t('cp.login.placeholder.password')"
    :label="$t('cp.login.label.password')"
    :error-text="(v$.passwordInput.$errors[0]?.$message as string | undefined)"
    autocomplete="password"
    ><template #trailing>
      <Icon
        size="21"
        class="cursor-pointer"
        :name="
          passwordVisibility
            ? 'material-symbols:visibility'
            : 'material-symbols:visibility-off'
        "
        @click="passwordVisibility = !passwordVisibility"
      /> </template
  ></CPFormInputField>
</template>
