<script setup lang="ts">
import { tv } from 'tailwind-variants'
import { bookingCredentialsSection } from './bookingCredentialsSectionStyles'
import { LoginView } from '~/enums'

const theme = useRuntimeConfig().public
  .theme as keyof typeof bookingCredentialsSection
const bookingCredentialsStyling = bookingCredentialsSection[theme]

const styling = tv({
  extend: bookingCredentialsStyling,
})
const loginPage = useState('loginPage')

const { wrapper, text1, text2, link, divider } = styling()
</script>

<template>
  <div :class="wrapper()">
    <div :class="text1()">{{ $t('cp.login.booking-credentials.or') }}</div>
    <CPLink :class="link()" @click="loginPage = LoginView.bookingSignIn">
      {{ $t('cp.login.booking-credentials.link') }}
      <Icon size="24" name="material-symbols-light:chevron-right-rounded" />
    </CPLink>
    <div :class="divider()"></div>
    <div :class="text2()">
      {{ $t('cp.login.booking-credentials.description') }}
    </div>
  </div>
</template>
