export const bookingCredentialsSection = {
  alpha: {
    slots: {
      divider: 'border-b border-brand-gray-300 my-xs',
      wrapper:
        'bg-brand-white p-sm pt-xs rounded-b-lg text-center border-brand-gray-300 border border-t-0',
      text1: 'text-brand-gray-800 mx-auto',
      text2: 'text-brand-gray-800 leading-5 text-xs',
      link: 'leading-7 mx-auto',
    },
  },
  bravo: {
    slots: {
      divider: 'border-b border-brand-gray-300 my-xs',
      wrapper:
        'bg-brand-white p-sm pt-xs rounded-b-lg text-center border-brand-gray-300 border border-t-0',
      text1: 'text-brand-gray-800 mx-auto',
      text2: 'text-brand-gray-800 leading-5 text-xs',
      link: 'leading-7 mx-auto',
    },
  },
  charlie: {
    slots: {
      divider: 'border-b border-brand-gray-300 my-xs',
      wrapper:
        'bg-brand-white p-sm pt-xs rounded-b-lg text-center border-brand-gray-300 border border-t-0',
      text1: 'text-brand-gray-1000 font-p14 mx-auto',
      text2: 'text-brand-gray-1000 font-p12 ',
      link: 'mx-auto',
    },
  },
}
